import Container from "../components/container";
import {useEffect, useState} from "react";
import RichEditor from "../components/wysiwyg/rich-editor";

export default function BlogLanding2() {
    const [tableTitles, setTableTitles] = useState([]);
    const [tableColumns, setTableColumns] = useState('auto');
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setTableTitles(['Title', 'Category', 'Timestamp', 'Status']);

        setTableColumns('auto auto auto auto');

        const tableRows = [];
        for (let i = 0; i < 10; i++) {
            tableRows.push({
                'row_title': 'New Saber Facility Renovation Project Set to Start',
                'row_category': 'News',
                'row_timestamp': '03/16/2018',
                'row_status': 'Active',
            })
        }

        setRows(tableRows);
    }, []);
    return (
        <Container breadcrumbs={<div className={'breadcrumbs'}>
            <a className={'previous mobile'}>Home</a>
            <a className={'previous'} href={'/'}>/</a>
            <a href={'/blog-landing'} className={'previous'}>Blog</a>
            <span>/</span>
            New Saber Facility...
        </div>}>
            <div className={'table-options-container'}>
                <div className="buttons">
                    <button type={'button'} className={'btn wire danger active'}>Posts</button>

                    <button type={'button'} className={'btn wire danger'}>Categories</button>
                </div>

                <div className="related">
                    Related <a href="#">Add Post</a>
                </div>
            </div>
            <div className="table-container">
                <div className={'table-container-inner'}>
                    <div className="table-heading">
                        <div className="heading-text-container">
                            <span className="material-symbols-outlined" style={{color: '#FFF'}}>article</span> <span>Posts</span>
                        </div>

                        <div className="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="filters-container">
                            <div className="row desktop">
                                <div className="related">
                                    Related <a href="#">Add Post</a>
                                </div>

                                <div className="pagination-container desktop">
                                    <div className="left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g opacity="0.5">
                                                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="black"/>
                                            </g>
                                        </svg>
                                    </div>

                                    <div className="center">
                                        1 OF 40
                                    </div>

                                    <div className="right">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M13.1716 11.9993L8.2218 7.0496L9.636 5.6354L16 11.9993L9.636 18.3633L8.2218 16.9491L13.1716 11.9993Z" fill="#FE5C5C"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <input type="text" className={'normal'} placeholder={'Search by title, author, etc'} />

                                <div className="select-overlay-container normal">
                                    <select name="category" className={'hidden'}>
                                        <option value="Select a category">Select a category</option>
                                    </select>

                                    <div className="select-overlay">
                                        <span>Select a category</span>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                            <g opacity="0.5">
                                                <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rows-container" style={{'gridTemplateColumns': tableColumns}}>
                            {tableTitles.map((title, index) => {
                                return <div className={'table-title'} key={'table-title-' + index}>{title}</div>
                            })}

                            <div className="separator" style={{gridColumn: 'span ' + tableTitles.length}}></div>

                            {rows.map((row, outerIndex) => {
                                return Object.values(row).map((column, index) => {
                                    return <div className={'table-column'} key={'table-column-' + outerIndex + '-' + index}>
                                        {column}
                                    </div>
                                });
                            })}
                        </div>

                        <div className="pagination-container-mobile">
                            <div className="pagination-container">
                                <div className="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g opacity="0.5">
                                            <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="black"/>
                                        </g>
                                    </svg>
                                </div>

                                <div className="center">
                                    1 OF 40
                                </div>

                                <div className="right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M13.1716 11.9993L8.2218 7.0496L9.636 5.6354L16 11.9993L9.636 18.3633L8.2218 16.9491L13.1716 11.9993Z" fill="#FE5C5C"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'table-container-inner desktop'}>
                    <div className="table-heading">
                        <div className="heading-text-container">
                            <span className="material-symbols-outlined" style={{color: '#FFF'}}>article</span> <span>New Saber Facility Renovation Project Set to Start</span>
                        </div>

                        <div className="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="fieldset">
                            <div className="posts-info">
                                Post Info
                            </div>

                            <div className="form-fields">
                                <div className="form-field-container normal">
                                    <label>
                                        TITLE*
                                    </label>

                                    <input type="text" className={'normal'} value={'New Saber Facility Renovation Project Set to Start'} />
                                </div>

                                <div className="form-field-container normal">
                                    <label>
                                        SLUG*
                                    </label>

                                    <input type="text" className={'normal'} value={'new-saber-facility-renovation-project-set-to-start'} />
                                </div>

                                <div className="form-field-container small">
                                    <label>
                                        TIMESTAMP
                                    </label>

                                    <div className="calendar-input">
                                        <input type="text" className={'normal'} value={'02/16/2018, 01:00 AM'} />

                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'calendar-icon'}>
                                            <path d="M6.66667 0.666656V1.99999H10.6667V0.666656H12V1.99999H14.6667C15.0349 1.99999 15.3333 2.29847 15.3333 2.66666V13.3333C15.3333 13.7015 15.0349 14 14.6667 14H2.66667C2.29848 14 2 13.7015 2 13.3333V2.66666C2 2.29847 2.29848 1.99999 2.66667 1.99999H5.33333V0.666656H6.66667ZM14 7.33332H3.33333V12.6667H14V7.33332ZM5.33333 3.33332H3.33333V5.99999H14V3.33332H12V4.66666H10.6667V3.33332H6.66667V4.66666H5.33333V3.33332Z" fill="black"/>
                                        </svg>
                                    </div>
                                </div>

                                <div className="form-field-container small">
                                    <label>
                                        Event Start
                                    </label>

                                    <div className="calendar-input">
                                        <input type="text" className={'normal'} value={'mm/dd/yyyy, --:-- --'} />

                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'calendar-icon'}>
                                            <path d="M6.66667 0.666656V1.99999H10.6667V0.666656H12V1.99999H14.6667C15.0349 1.99999 15.3333 2.29847 15.3333 2.66666V13.3333C15.3333 13.7015 15.0349 14 14.6667 14H2.66667C2.29848 14 2 13.7015 2 13.3333V2.66666C2 2.29847 2.29848 1.99999 2.66667 1.99999H5.33333V0.666656H6.66667ZM14 7.33332H3.33333V12.6667H14V7.33332ZM5.33333 3.33332H3.33333V5.99999H14V3.33332H12V4.66666H10.6667V3.33332H6.66667V4.66666H5.33333V3.33332Z" fill="black"/>
                                        </svg>
                                    </div>
                                </div>

                                <div className="form-field-container small">
                                    <label>
                                        Event End
                                    </label>

                                    <div className="calendar-input">
                                        <input type="text" className={'normal'} value={'mm/dd/yyyy, --:-- --'} />

                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'calendar-icon'}>
                                            <path d="M6.66667 0.666656V1.99999H10.6667V0.666656H12V1.99999H14.6667C15.0349 1.99999 15.3333 2.29847 15.3333 2.66666V13.3333C15.3333 13.7015 15.0349 14 14.6667 14H2.66667C2.29848 14 2 13.7015 2 13.3333V2.66666C2 2.29847 2.29848 1.99999 2.66667 1.99999H5.33333V0.666656H6.66667ZM14 7.33332H3.33333V12.6667H14V7.33332ZM5.33333 3.33332H3.33333V5.99999H14V3.33332H12V4.66666H10.6667V3.33332H6.66667V4.66666H5.33333V3.33332Z" fill="black"/>
                                        </svg>
                                    </div>
                                </div>

                                <div className="form-field-container large">
                                    <label>
                                        Summary*
                                    </label>

                                    <textarea name={'test'} value={'The new management of a Parksley nursing home announced the facility will soon undergo a multimillion-dollar renovation.'}></textarea>
                                </div>
                            </div>
                        </div>


                        <div className="fieldset">
                            <div className="posts-info">
                                Post Settings
                            </div>

                            <div className="form-fields">
                                <div className="form-field-container small">
                                    <label>
                                        Category
                                    </label>

                                    <div className="select-overlay-container normal selected">
                                        <select name="category" className={'hidden'}>
                                            <option value="Select a category">Select a category</option>
                                        </select>

                                        <div className="select-overlay">
                                            <span>News</span>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                <g>
                                                    <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container small">
                                    <label>
                                        Author
                                    </label>

                                    <div className="select-overlay-container normal">
                                        <select name="category" className={'hidden'}>
                                            <option value="Select a category">Select a category</option>
                                        </select>

                                        <div className="select-overlay">
                                            <span>Select</span>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                <g>
                                                    <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container small">
                                    <label>
                                        Status
                                    </label>

                                    <div className="select-overlay-container normal selected">
                                        <select name="category" className={'hidden'}>
                                            <option value="Select a category">Select a category</option>
                                        </select>

                                        <div className="select-overlay">
                                            <span>Active</span>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                <g>
                                                    <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container large">
                                    <label>
                                        Status
                                    </label>

                                    <div className="form-fields">
                                        <div className="form-field-container normal">
                                            <div className="select-overlay-container normal selected">
                                                <select name="category" className={'hidden'}>
                                                    <option value="Select a category">Select a category</option>
                                                </select>

                                                <div className="select-overlay">
                                                    <span>Active</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                        <g>
                                                            <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-field-container normal">
                                            <input type="text" placeholder={'Location Address'}/>
                                        </div>

                                        <div className="form-field-container normal">
                                            <input type="text" placeholder={'Latitude'}/>
                                        </div>

                                        <div className="form-field-container normal">
                                            <input type="text" placeholder={'Longitude'}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container large">
                                    <label>
                                        Tags
                                    </label>

                                    <textarea placeholder={'comma-separated'}></textarea>
                                </div>

                            </div>
                        </div>

                        <div className="fieldset">
                            <div className="posts-info">
                                Post Content
                            </div>

                            <div className="form-fields">
                                <div className="form-field-container large">
                                    <label>Content*</label>

                                    <RichEditor content={''} />
                                </div>

                                <div className="form-field-container large">
                                    <label>Images</label>

                                    <div className="images-container">
                                        <figure className="image-container">
                                            <img src="/images/uploaded-image.png" alt="Image" className={'image'} />

                                            <figcaption className={'caption'}>
                                                NewRenovation.jpg
                                            </figcaption>
                                        </figure>

                                        <div className="image-upload">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g opacity="0.5">
                                                    <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" fill="black"/>
                                                </g>
                                            </svg>

                                            <div>
                                                Upload
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-field-container large">
                            <div className="actions">
                                <button type={'button'} className={'btn wire danger'}>Delete</button>

                                <button type={'button'} className={'btn danger'}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
