
export const cardColors = [
    '#FFAE42',
    '#F38500',
    '#FE5C5C',
    '#D40CA9',
    '#6C6C6C',
];

export const mockComponents = [
    {
        'component_title': 'Blog',
        'component_icon': 'article',
        'component_children': [
            {
                'component_title': 'Posts',
            },
            {
                'component_title': 'Categories',
            }
        ]
    },
    {
        'component_title': 'Contents',
        'component_icon': 'content_copy',
    },
    {
        'component_title': 'Galleries',
        'component_icon': 'collections',
    },
    {
        'component_title': 'Testimonials',
        'component_icon': 'comment',
    },
    {
        'component_title': 'Locations',
        'component_icon': 'comment',
    },
    {
        'component_title': 'States',
        'component_icon': 'comment',
    },
    {
        'component_title': 'Resources',
        'component_icon': 'comment',
    },
    {
        'component_title': 'Biographies',
        'component_icon': 'comment',
    },
    {
        'component_title': 'Users',
        'component_icon': 'comment',
    },
    {
        'component_title': 'Settings',
        'component_icon': 'comment',
    }
];
