
export default function Login() {
    return (
        <>
            <div className={`login-container`}>
                <div className="form-container">
                    <div className="logo-container">
                        <img src={"/images/iris-icon.svg"} alt={""} width={36} height={28} className={'header-logo-image'} />
                        <img src={"/images/iris-text-dark.svg"} alt={""} width={62} height={32} className={'header-logo-text'} />
                    </div>

                    <form action="/" className="form">
                        <input type="text" placeholder={'Email'} />

                        <input type="password" placeholder={'Password'} />

                        <div className="options">
                            <div className="left">
                                <input type="checkbox" /> Keep me logged in
                            </div>

                            <div className="right">
                                <a href="#">
                                    Forgot password?
                                </a>
                            </div>
                        </div>

                        <div className="submit-container">
                            <button type={'submit'} className={'btn danger'}>
                                Login
                            </button>
                        </div>

                        <div className="mobile-forgot-password">
                            <a href="#">
                                Forgot password?
                            </a>
                        </div>
                    </form>
                </div>

                <div className="logo-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="188" height="16" viewBox="0 0 188 16" fill="none">
                        <path d="M145.56 0.0488662C146.255 0.0488662 146.931 0.0155865 147.599 0.0710526C147.778 0.0858436 147.984 0.338523 148.096 0.529572C150.345 4.36906 152.584 8.21471 154.824 12.0591C154.948 12.2711 155.078 12.4782 155.256 12.7691C155.412 12.5152 155.539 12.318 155.658 12.1146C157.911 8.25292 160.168 4.39371 162.408 0.524642C162.606 0.183217 162.807 0.00695848 163.221 0.0353078C163.789 0.0735178 164.361 0.0451685 164.969 0.0451685V15.6546H162.066V7.14853C162.031 7.13497 161.997 7.12018 161.962 7.10662C161.8 7.37532 161.632 7.64033 161.473 7.91149C160.053 10.336 158.626 12.7568 157.224 15.1911C157 15.5794 156.751 15.7347 156.302 15.7113C155.586 15.6731 154.865 15.6767 154.148 15.7113C153.745 15.7297 153.524 15.5905 153.322 15.2404C151.876 12.7358 150.408 10.2448 148.945 7.75003C148.834 7.56021 148.714 7.37409 148.478 7.20153V15.662H145.558V0.0488662H145.56Z" fill="black"/>
                        <path d="M87.6341 15.699C86.4607 15.699 85.4364 15.72 84.4146 15.6805C84.2285 15.6731 83.998 15.4537 83.8772 15.2725C82.5657 13.2869 81.2641 11.2925 79.9785 9.2896C79.7678 8.9605 79.5434 8.82122 79.1404 8.82861C77.7278 8.85573 76.3141 8.83971 74.8411 8.83971V15.6966H71.9458V0.0908548C72.1245 0.0748312 72.2835 0.0477144 72.4413 0.0477144C75.646 0.0452493 78.8507 0.025528 82.0542 0.0514122C85.4672 0.0797615 87.5897 3.38431 86.1464 6.37948C85.6213 7.46908 84.9101 8.04839 83.1524 8.79041C84.6253 11.0608 86.0897 13.3201 87.6328 15.699H87.6341ZM74.8584 5.86673C74.9656 5.88768 75.042 5.91603 75.1197 5.91603C77.4764 5.91849 79.8331 5.92712 82.191 5.90863C82.4622 5.90617 82.7592 5.8051 82.9959 5.66705C83.5579 5.33795 83.7995 4.66742 83.6294 4.07332C83.4359 3.3991 82.8702 2.96893 82.1097 2.96646C79.8553 2.95784 77.6009 2.96277 75.3465 2.96646C75.1899 2.96646 75.0334 2.99605 74.8596 3.01453V5.86796L74.8584 5.86673Z" fill="black"/>
                        <path d="M174.133 15.7409C174.745 14.7597 175.268 13.8821 175.842 13.0415C175.945 12.8924 176.264 12.8344 176.483 12.8332C178.579 12.8184 180.674 12.827 182.77 12.8234C184.128 12.8209 184.766 12.1799 184.77 10.8278C184.77 10.3397 184.77 9.8516 184.77 9.29078C184.463 9.29078 184.223 9.29078 183.984 9.29078C181.848 9.29078 179.71 9.29571 177.574 9.28955C174.959 9.28215 172.913 7.23483 172.916 4.64272C172.921 2.0617 174.988 0.00944946 177.593 0.00575172C179.401 0.00328656 181.208 0.00205398 183.016 0.00575172C185.586 0.010682 187.678 2.09621 187.686 4.6686C187.692 6.82562 187.694 8.98263 187.686 11.1396C187.678 13.664 185.62 15.731 183.101 15.7396C180.165 15.7483 177.228 15.7421 174.134 15.7421L174.133 15.7409ZM184.728 6.39421C184.728 5.78532 184.733 5.27503 184.728 4.76474C184.717 3.65172 184.04 2.93806 182.941 2.9282C181.156 2.91218 179.371 2.91464 177.588 2.9282C176.54 2.93559 175.791 3.65172 175.773 4.6193C175.756 5.5955 176.541 6.38189 177.588 6.39175C179.126 6.40654 180.664 6.39545 182.203 6.39545C183.018 6.39545 183.836 6.39545 184.729 6.39545L184.728 6.39421Z" fill="black"/>
                        <path d="M111.557 12.8086V15.6621H97.4897V0.0847168H111.56V2.9443H100.353V6.3302H108.923V9.23539H100.353V12.8074H111.556L111.557 12.8086Z" fill="black"/>
                        <path d="M26.6325 15.6274V12.7826C26.8642 12.7826 27.0799 12.7826 27.2969 12.7826C29.7608 12.7826 32.2247 12.7851 34.6874 12.7789C34.9721 12.7789 35.2643 12.7555 35.5403 12.6902C36.4722 12.4708 37.054 11.7855 37.0207 10.9793C36.9874 10.1757 36.2861 9.46574 35.3604 9.35357C34.8735 9.29441 34.378 9.29564 33.8862 9.29317C32.5933 9.28578 31.299 9.30057 30.0061 9.28824C27.7172 9.26606 25.6526 7.63659 25.2557 5.55229C24.7676 2.9836 26.4784 0.536927 29.1124 0.119082C29.8964 -0.00540826 30.7062 0.0155456 31.5049 0.00815012C33.1048 -0.00664084 34.7047 0.0032198 36.3575 0.0032198V2.88992C36.1382 2.89978 35.9212 2.91827 35.7043 2.91827C33.8973 2.92074 32.0904 2.88869 30.2846 2.93923C29.778 2.95402 29.2135 3.10562 28.7883 3.37309C27.8158 3.98445 27.8232 5.33413 28.8018 5.93686C29.2308 6.20186 29.7928 6.35347 30.3007 6.37073C31.9203 6.42496 33.5448 6.37073 35.1656 6.39538C37.9587 6.43852 40.1009 8.71879 39.9209 11.4268C39.7792 13.5715 37.8933 15.5288 35.6279 15.6188C32.6968 15.7359 29.7595 15.673 26.8235 15.6841C26.7656 15.6841 26.7089 15.6484 26.6313 15.6237L26.6325 15.6274Z" fill="black"/>
                        <path d="M138.338 15.7398C137.268 15.7398 136.289 15.7583 135.313 15.7213C135.148 15.7151 134.939 15.5044 134.841 15.3355C134.396 14.575 134.003 13.7837 133.544 13.0318C133.434 12.8519 133.146 12.6781 132.938 12.6756C130.269 12.6522 127.599 12.6509 124.931 12.6781C124.71 12.6805 124.408 12.8802 124.287 13.0762C123.826 13.8268 123.438 14.6206 122.989 15.3799C122.895 15.5413 122.667 15.7201 122.495 15.7262C121.535 15.7595 120.572 15.7423 119.523 15.7423C119.625 15.5216 119.686 15.3589 119.769 15.2061C122.434 10.3078 125.105 5.412 127.761 0.508798C127.939 0.178466 128.143 0.0749295 128.515 0.029324C129.404 -0.0816082 129.881 0.238863 130.318 1.05113C132.854 5.76329 135.463 10.436 138.045 15.1235C138.14 15.2948 138.213 15.4772 138.338 15.7423V15.7398ZM131.68 9.70754C130.726 7.96837 129.814 6.30439 128.85 4.54796C127.88 6.32165 126.972 7.98193 126.028 9.70754H131.68Z" fill="black"/>
                        <path d="M56.5498 15.6608H53.6705V2.97141H47.1279V0.0982666H63.0763V2.96278H56.5498V15.6596V15.6608Z" fill="black"/>
                        <path d="M13.582 5.58415H9.07446V1.05688H13.582V5.58415Z" fill="#FB512F"/>
                        <path d="M9.05713 14.6809V10.1832H13.577V14.6809H9.05713Z" fill="#FB512F"/>
                        <path d="M4.50754 5.58415H0V1.05688H4.50754V5.58415Z" fill="#FB512F"/>
                    </svg>
                </div>
            </div>
        </>
    );
}
