import Container from "../components/container";
import {useState} from "react";

export default function InputTypes() {
    const [searchSelected, setSearchSelected] = useState(false);

    return (
        <Container breadcrumbs={<div className={'breadcrumbs'}>
            <a className={'previous mobile'}>Home</a>
            <span>/</span>
            Input Types
        </div>}>
            <div className="table-container">
                <div className={'table-container-inner'}>
                    <div className="table-heading">
                        <div className="heading-text-container">
                            <span>Input Types</span>
                        </div>

                        <div className="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="fieldset">
                            <div className="posts-info">
                                Editable list field
                            </div>

                            <div className="form-fields">
                                <div className="form-field-container large">
                                    <div className="editable-list-fields">
                                        <div className="label">Store</div>

                                        <div className="label">Custom Text</div>

                                        <div></div>

                                        <div className="select-overlay-container selected">
                                            <select name="category" className={'hidden'}>
                                                <option value="Select a category">Select a category</option>
                                            </select>

                                            <div className="select-overlay">
                                                <span>Z EZ Dumper</span>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                    <g>
                                                        <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                        <input type="text" value={'test'} />

                                        <div className="delete-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={'delete'}>
                                                <path d="M11.3335 4.00001H14.6668V5.33334H13.3335V14C13.3335 14.3682 13.035 14.6667 12.6668 14.6667H3.3335C2.96531 14.6667 2.66683 14.3682 2.66683 14V5.33334H1.3335V4.00001H4.66683V2.00001C4.66683 1.63182 4.96531 1.33334 5.3335 1.33334H10.6668C11.035 1.33334 11.3335 1.63182 11.3335 2.00001V4.00001ZM12.0002 5.33334H4.00016V13.3333H12.0002V5.33334ZM6.00016 7.33334H7.3335V11.3333H6.00016V7.33334ZM8.66683 7.33334H10.0002V11.3333H8.66683V7.33334ZM6.00016 2.66668V4.00001H10.0002V2.66668H6.00016Z" fill="black"/>
                                            </svg>
                                        </div>

                                        <div className="select-overlay-container selected">
                                            <select name="category" className={'hidden'}>
                                                <option value="Select a category">Select a category</option>
                                            </select>

                                            <div className="select-overlay">
                                                <span>2022 LCD Canada</span>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                                    <g>
                                                        <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                        <input type="text" value={'test2'} />

                                        <div className="delete-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={'delete'}>
                                                <path d="M11.3335 4.00001H14.6668V5.33334H13.3335V14C13.3335 14.3682 13.035 14.6667 12.6668 14.6667H3.3335C2.96531 14.6667 2.66683 14.3682 2.66683 14V5.33334H1.3335V4.00001H4.66683V2.00001C4.66683 1.63182 4.96531 1.33334 5.3335 1.33334H10.6668C11.035 1.33334 11.3335 1.63182 11.3335 2.00001V4.00001ZM12.0002 5.33334H4.00016V13.3333H12.0002V5.33334ZM6.00016 7.33334H7.3335V11.3333H6.00016V7.33334ZM8.66683 7.33334H10.0002V11.3333H8.66683V7.33334ZM6.00016 2.66668V4.00001H10.0002V2.66668H6.00016Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>

                                    <button type={'button'} className="add-more">
                                        <span>Add more</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g opacity="0.5">
                                                <path d="M7.33325 7.33334V3.33334H8.66658V7.33334H12.6666V8.66668H8.66658V12.6667H7.33325V8.66668H3.33325V7.33334H7.33325Z" fill="black"/>
                                            </g>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div className="fieldset">
                            <div className="posts-info">
                                Searchable multi-select
                            </div>

                            <div className={'form-fields'}>
                                <div className="form-field-container large">

                                    <div className="searchable-multi-select">
                                        <div>
                                            <label className={'label'}>Assigned Stores</label>

                                            <div className="searchable-input-container">
                                                <input type="text" className={'search'} value={'amerisurel'}
                                                       onFocus={() => setSearchSelected(true)}
                                                       onBlur={() => setSearchSelected(false)}
                                                />

                                                {searchSelected && (
                                                    <div className="close-container" onClick={() => setSearchSelected(false)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'close'}>
                                                            <path d="M8.50047 7.05767L11.8003 3.75781L12.7431 4.70062L9.44327 8.00047L12.7431 11.3003L11.8003 12.2431L8.50047 8.94327L5.20062 12.2431L4.25781 11.3003L7.55767 8.00047L4.25781 4.70062L5.20062 3.75781L8.50047 7.05767Z" fill="black"/>
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>

                                            {searchSelected && (
                                                <div className="results-container">
                                                    <div className="result selected">
                                                        <span>amerisure</span>
                                                    </div>

                                                    <div className="result selected">
                                                        <span>amerisure</span>
                                                    </div>

                                                    <div className="result">
                                                        <span>amerisure</span>
                                                    </div>

                                                    <div className="result">
                                                        <span>amerisure</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            <label className="label">Author</label>

                                            <div className="selected-grid">
                                                <div className="selected">
                                                    <span>amerisure</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'remove'}>
                                                        <path d="M8.75047 7.05767L12.0503 3.75781L12.9931 4.70062L9.69327 8.00047L12.9931 11.3003L12.0503 12.2431L8.75047 8.94327L5.45062 12.2431L4.50781 11.3003L7.80767 8.00047L4.50781 4.70062L5.45062 3.75781L8.75047 7.05767Z" fill="#FE5C5C"/>
                                                    </svg>
                                                </div>

                                                <div className="selected">
                                                    <span>amerisure</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'remove'}>
                                                        <path d="M8.75047 7.05767L12.0503 3.75781L12.9931 4.70062L9.69327 8.00047L12.9931 11.3003L12.0503 12.2431L8.75047 8.94327L5.45062 12.2431L4.50781 11.3003L7.80767 8.00047L4.50781 4.70062L5.45062 3.75781L8.75047 7.05767Z" fill="#FE5C5C"/>
                                                    </svg>
                                                </div>

                                                <div className="selected">
                                                    <span>amerisure</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'remove'}>
                                                        <path d="M8.75047 7.05767L12.0503 3.75781L12.9931 4.70062L9.69327 8.00047L12.9931 11.3003L12.0503 12.2431L8.75047 8.94327L5.45062 12.2431L4.50781 11.3003L7.80767 8.00047L4.50781 4.70062L5.45062 3.75781L8.75047 7.05767Z" fill="#FE5C5C"/>
                                                    </svg>
                                                </div>

                                                <div className="selected">
                                                    <span>amerisure</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={'remove'}>
                                                        <path d="M8.75047 7.05767L12.0503 3.75781L12.9931 4.70062L9.69327 8.00047L12.9931 11.3003L12.0503 12.2431L8.75047 8.94327L5.45062 12.2431L4.50781 11.3003L7.80767 8.00047L4.50781 4.70062L5.45062 3.75781L8.75047 7.05767Z" fill="#FE5C5C"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="fieldset">
                            <div className="posts-info">
                                Multi-select
                            </div>

                            <div className={'form-fields'}>
                                <div className="form-field-container normal">

                                    <div className="multi-select">
                                        <div>
                                            <label className={'label'}>Roles</label>

                                            <div className="results-container">
                                                <div className="result selected">
                                                    <span>Super Admin</span>
                                                </div>

                                                <div className="result selected">
                                                    <span>Administrator</span>
                                                </div>

                                                <div className="result">
                                                    <span>Customer</span>
                                                </div>

                                                <div className="result">
                                                    <span>Test Customer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-field-container large">
                            <div className="actions">
                                <button type={'button'} className={'btn wire danger'}>Delete</button>

                                <button type={'button'} className={'btn danger'}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
