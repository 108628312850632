import {useEffect, useState} from "react";
import {mockComponents} from "../config";

export default function Container({children, breadcrumbs, related}) {
    const [viewModeSplit, setViewModeSplit] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        setLinks(mockComponents);
    }, []);

    useEffect(() => {
        if (sidebarActive) {
            document.querySelector('body').classList.add('no-scroll');
        } else {
            document.querySelector('body').classList.remove('no-scroll');
        }
    }, [sidebarActive])

    return <>
        <main className={`main`}>
            <header className={`header`}>
                <div className={'header-logo'}>
                    <a href={'/'}>
                        <img src={"/images/iris-icon.svg"} alt={""} width={36} height={28} className={'header-logo-image'} />
                    </a>
                    {!breadcrumbs ? (
                        <img src={"/images/iris-text.svg"} alt={""} width={62} height={32} className={'header-logo-text'} />
                    ) : breadcrumbs}

                    <img src={"/images/iris-text.svg"} alt={""} width={62} height={32} className={'header-logo-text mobile'} />
                </div>

                <div className={'view-mode-container'}>
                    {viewModeSplit ? (
                        <>
                            <div onClick={() => setViewModeSplit(true)} className={'view-mode-icon-active'}>
                                <img src={"/images/view-mode-split-dark.svg"} alt={""} width={24} height={24}
                                       className={'view-mode-icon-split'} />
                            </div>

                            <div onClick={() => setViewModeSplit(false)} className={'view-mode-icon'}>
                                <img src={"/images/view-mode-single-light.svg"} alt={""} width={24} height={24}
                                       className={'view-mode-icon-single'} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div onClick={() => setViewModeSplit(true)} className={'view-mode-icon'}>
                                <img src={"/images/view-mode-split-light.svg"} alt={""} width={24} height={24}
                                       className={'view-mode-icon-split'} />
                            </div>

                            <div onClick={() => setViewModeSplit(false)} className={'view-mode-icon-active'}>
                                <img src={"/images/view-mode-single-dark.svg"} alt={""} width={24} height={24}
                                       className={'view-mode-icon-single'} />
                            </div>
                        </>
                    )}
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"
                     className={'mobile-menu-icon'} onClick={() => setSidebarActive(true)}>
                    <path d="M4 5.33337H28V8.00004H4V5.33337ZM4 14.6667H28V17.3334H4V14.6667ZM4 24H28V26.6667H4V24Z" fill="white"/>
                </svg>
            </header>
            <div className={"sidebar-container " + (sidebarActive ? 'active' : '')}>
                <div className="sidebar">
                    <div className="sidebar-header">
                        <div className="logo-container">
                            <img src={"/images/iris-icon.svg"} alt={""} width={36} height={28} className={'header-logo-image'} />

                            <img src={"/images/iris-text.svg"} alt={""} width={62} height={32} className={'header-logo-text'} />
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"
                             className={'close'} onClick={() => setSidebarActive(false)}>
                            <path d="M15.9997 14.1153L22.5994 7.51562L24.485 9.40124L17.8853 16.0009L24.485 22.6005L22.5994 24.4861L15.9997 17.8865L9.40003 24.4861L7.5144 22.6005L14.1141 16.0009L7.5144 9.40124L9.40003 7.51562L15.9997 14.1153Z" fill="white"/>
                        </svg>
                    </div>

                    <div className="links-container">
                        <div className="links">
                            {links.map((link, index) => {
                                if (link.component_title === 'Blog') {
                                    return <div className="active-group" key={'sidebar-link-' + index}>
                                        <div className="link active">
                                            <span className="material-symbols-outlined">{link.component_icon}</span>
                                            <span>{link.component_title}</span>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={'arrow'}>
                                                <path d="M15.9991 17.5623L22.5988 10.9626L24.4844 12.8482L15.9991 21.3335L7.51387 12.8482L9.39947 10.9626L15.9991 17.5623Z" fill="white"/>
                                            </svg>
                                        </div>

                                        {link.component_children && link.component_children.map((child, childIndex) => {
                                            return <a href="#"
                                                      key={'link-child-' + index + '-' + childIndex}
                                                      className={'link child ' + (child.component_title === 'Posts' ? 'active' : '')}>
                                                <span>{child.component_title}</span>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={'arrow child-arrow'}>
                                                    <path d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33325V14.6665H21.5621Z" fill="#6C6C6C"/>
                                                </svg>
                                            </a>
                                        })}
                                    </div>
                                }

                                return <a href="#" className="link" key={'sidebar-link-' + index}>
                                    <span className="material-symbols-outlined">{link.component_icon}</span>
                                    <span>{link.component_title}</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={'arrow'}>
                                        <path d="M15.9991 17.5623L22.5988 10.9626L24.4844 12.8482L15.9991 21.3335L7.51387 12.8482L9.39947 10.9626L15.9991 17.5623Z" fill="white"/>
                                    </svg>
                                </a>
                            })}
                        </div>

                        <a href="#" className={'logout'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 22C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V6H18V4H6V20H18V18H20V21C20 21.5523 19.5523 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z" fill="white"/>
                            </svg>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>

                <div className="sidebar-action" onClick={() => setSidebarActive(!sidebarActive)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                        <path d="M3.1111 4.00003L0 0.88889L0.88889 0L4.88893 4.00003L0.88889 8L0 7.11112L3.1111 4.00003Z" fill="white"/>
                    </svg>
                </div>
            </div>

            <div className="breadcrumbs-container">
                {breadcrumbs} {related}
            </div>

            {children}
        </main>
    </>
};
