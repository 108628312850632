import {useEffect, useState} from "react";
import Container from "../components/container";
import {getColor} from "../utils";
import {mockComponents} from "../config";

export default function Home() {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        setCards(mockComponents);
    }, []);

  return (
    <>
        <Container>
            <div className={'table-container'}>
                <div className="card-table">
                    {cards.map((card, index) => {
                        return <div className={'card'} style={{borderBottom: '5px solid ' + getColor(index)}}>
                            <div className={'card-icon'}>
                                <span className="material-symbols-outlined" style={{color: getColor(index)}}>{card.component_icon}</span>
                            </div>

                            <div className={'card-text'}>
                                <div className={'card-title'} style={{color: getColor(index)}}>
                                    {card.component_title}
                                </div>

                                <div className={'card-arrow-container'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M12.1716 7.00005L6.8076 1.63604L8.2218 0.221832L16 8.00005L8.2218 15.7782L6.8076 14.364L12.1716 9.00005H0V7.00005H12.1716Z" fill={getColor(index)} />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </Container>

    </>
  )
}
