import {useEffect, useState} from "react";
import Container from "../components/container";
import {getColor} from "../utils";
import Card from "../components/card";

export default function BlogLanding() {
    const [cards, setCards] = useState([]);
    const [viewDouble, setViewDouble] = useState(false);

    useEffect(() => {
        setCards([
            {
                'component_title': 'Blog',
                'component_icon': 'article',
            },
            {
                'component_title': 'Contents',
                'component_icon': 'content_copy',
            },
        ]);
    }, []);

    return (
        <Container breadcrumbs={<div className={'breadcrumbs'}>
            <a className={'previous mobile'}>Home</a> <a href={'/'}>/</a> Blog
        </div>}>
            <div className={'table-container'}>
                <div className="table-container-inner card-container">
                    <div className="table-heading">
                        <div className="heading-text-container">
                            <span className="material-symbols-outlined" style={{color: '#FFF'}}>article</span> <span>Blog</span>
                        </div>

                        <div className="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="cards">
                            {cards.map((card, index) => {
                                return <Card onClick={() => console.log('clicky')} color={getColor(index)} icon={card.component_icon} title={card.component_title} active={index === 0} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
