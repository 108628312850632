import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/index";
import BlogLanding from "./pages/blog-landing";
import BlogLandingDouble from "./pages/blog-double";
import IndividualPost from "./pages/individual-post";
import InputTypes from "./pages/input-types";
import BlogLanding2 from "./pages/blog-landing-2";
import SingleView from "./pages/single-view";
import IndividualPost2 from "./pages/individual-post-2";
import IndividualPostSingleView from "./pages/individual-post-single-view";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import Posts from "./pages/posts";
import Posts2 from "./pages/posts-2";

function App() {
    return (
        <BrowserRouter>
                <Routes>
                    <Route path={'/'} exact element={<Home />} />
                    <Route path={'/blog-landing'} exact element={<BlogLanding />} />
                    <Route path={'/blog-double'} exact element={<BlogLandingDouble />} />
                    <Route path={'/individual-post'} exact element={<IndividualPost />} />
                    <Route path={'/input-types'} exact element={<InputTypes />} />
                    <Route path={'/blog-landing-2'} exact element={<BlogLanding2 />} />
                    <Route path={'/single-view'} exact element={<SingleView />} />
                    <Route path={'/individual-post-2'} exact element={<IndividualPost2 />} />
                    <Route path={'/individual-post-single-view'} exact element={<IndividualPostSingleView />} />
                    <Route path={'/login'} exact element={<Login />} />
                    <Route path={'/forgot-password'} exact element={<ForgotPassword />} />
                    <Route path={'/reset-password'} exact element={<ResetPassword />} />
                    <Route path={'/posts'} exact element={<Posts />} />
                    <Route path={'/posts-2'} exact element={<Posts2 />} />
                </Routes>
        </BrowserRouter>
    );
}

export default App;
