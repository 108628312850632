export default function Card({color, icon, title, active}) {
    const cardBackground = () => {
        if (!active) {
            return '#FFF';
        }

        let hex = color.replace('#', '');
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        let r = parseInt(hex.substring(0, 2), 16),
            g = parseInt(hex.substring(2, 4), 16),
            b = parseInt(hex.substring(4, 6), 16);

        return `linear-gradient(0deg, rgba(${r}, ${g}, ${b}, 0.10) 0%, rgba(${r}, ${g}, ${b}, 0.10) 100%), #FFF`;
    }

    return (
        <div className={'card'} style={{borderBottom: '5px solid ' + color, background: cardBackground()}}>
            <div className={'card-icon'}>
                <span className="material-symbols-outlined" style={{color: color}}>{icon}</span>
            </div>

            <div className={'card-text'}>
                <div className={`card-title`} style={{color: color}}>
                    {title}
                </div>

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12.1716 7.00005L6.8076 1.63604L8.2218 0.221832L16 8.00005L8.2218 15.7782L6.8076 14.364L12.1716 9.00005H0V7.00005H12.1716Z" fill={color} />
                    </svg>
                </div>
            </div>
        </div>
    )
}
