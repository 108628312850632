import Container from "../components/container";
import {useEffect, useState} from "react";

export default function SingleView() {
    const [tableTitles, setTableTitles] = useState([]);
    const [tableColumns, setTableColumns] = useState('auto');
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setTableTitles(['Title', 'Category', 'Timestamp', 'Status']);

        setTableColumns('auto auto auto auto');

        const tableRows = [];
        for (let i = 0; i < 10; i++) {
            tableRows.push({
                'row_title': 'New Saber Facility Renovation Project Set to Start',
                'row_category': 'News',
                'row_timestamp': '03/16/2018',
                'row_status': 'Active',
            })
        }

        setRows(tableRows);
    }, []);
    return (
        <Container breadcrumbs={<div className={'breadcrumbs'}>
            <a className={'previous'} href={'/'}>/</a>
            <a href={'/blog-landing'} className={'previous'}>Blog</a>
            <a href={'/blog-landing'} className={'previous'}>/</a>
            <a href={'/blog-double'} className={'previous'}>Posts</a>
            <span>/</span>
            New Saber Facility...
        </div>}>
            <div className="table-container">
                <div className={'table-container-inner full-width'}>
                    <div className="table-heading">
                        <div className="heading-text-container">
                            <span className="material-symbols-outlined" style={{color: '#FFF'}}>article</span> <span>Posts</span>
                        </div>

                        <div className="close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                    </div>

                    <div className="table-body">
                        <div className="filters-container">
                            <div className="row">
                                <div className="related">
                                    Related <a href="#">Add Post</a>
                                </div>

                                <div className="pagination-container">
                                    <div className="left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g opacity="0.5">
                                                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" fill="black"/>
                                            </g>
                                        </svg>
                                    </div>

                                    <div className="center">
                                        1 OF 40
                                    </div>

                                    <div className="right">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M13.1716 11.9993L8.2218 7.0496L9.636 5.6354L16 11.9993L9.636 18.3633L8.2218 16.9491L13.1716 11.9993Z" fill="#FE5C5C"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="row filters">
                                <input type="text" className={'collapse'} placeholder={'Search by title, author, etc'} />

                                <div className="select-overlay-container expand">
                                    <select name="category" className={'hidden'}>
                                        <option value="Select a category">Select a category</option>
                                    </select>

                                    <div className="select-overlay">
                                        <span>Select a category</span>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={'arrow'}>
                                            <g opacity="0.5">
                                                <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" fill="black"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rows-container" style={{'gridTemplateColumns': tableColumns}}>
                            {tableTitles.map((title, index) => {
                                return <div className={'table-title'} key={'table-title-' + index}>{title}</div>
                            })}

                            <div className="separator" style={{gridColumn: 'span ' + tableTitles.length}}></div>

                            {rows.map((row, outerIndex) => {
                                return Object.values(row).map((column, index) => {
                                    return <div className={'table-column'} key={'table-column-' + outerIndex + '-' + index}>
                                        {column}
                                    </div>
                                });
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
